<script setup lang="ts">
import { Dialog, DialogPanel, DialogTitle, Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems, TransitionChild, TransitionRoot } from '@headlessui/vue'

import { z } from 'zod'

import { useLocalStorage } from '@vueuse/core'
import ArrowUturnLeftIcon from '@heroicons/vue/24/outline/esm/ArrowUturnLeftIcon'
import Bars3Icon from '@heroicons/vue/24/outline/esm/Bars3Icon'
import BellAlertIcon from '@heroicons/vue/24/outline/esm/BellAlertIcon'
import BellIcon from '@heroicons/vue/24/outline/esm/BellIcon'
import BookmarkIcon from '@heroicons/vue/24/outline/esm/BookmarkIcon'
import BookmarkSquareIcon from '@heroicons/vue/24/outline/esm/BookmarkSquareIcon'
import CogIcon from '@heroicons/vue/24/outline/esm/CogIcon'
import InboxIcon from '@heroicons/vue/24/outline/esm/InboxIcon'
import MicrophoneIcon from '@heroicons/vue/24/outline/esm/MicrophoneIcon'
import NewspaperIcon from '@heroicons/vue/24/outline/esm/NewspaperIcon'
import { format } from 'date-fns'
import VideoCameraIcon from '@heroicons/vue/24/outline/esm/VideoCameraIcon'
import XMarkIcon from '@heroicons/vue/24/outline/esm/XMarkIcon'
import GiftIcon from '@heroicons/vue/24/outline/esm/GiftIcon'

import WhatsappFloatingButton from '~/components/WhatsappFloatingButton.vue'

const whatsappFloatingButton = ref<typeof WhatsappFloatingButton>()

const navigationItems = computed(() => {
  return [
    { name: 'Home', href: '/' },
    { name: 'Academy', href: '/academy' },
    { name: 'I tuoi Corsi', href: '/lessons' },
    { name: 'Masterclass', href: '/masterclass' },
    { name: 'Tutor', href: '/tutor' },
    { name: 'Negozio', href: '/shop' },
    { name: 'Mangusta', href: '/mangusta' },
  ]
})

const user = useAuthUser()
const auth = useAuth()

const canAccessAdminArea = computed(() => {
  const rolesWithAdminAreaAccess = [
    'ADMIN',
    'MODERATOR',
    'EDITOR',
    'SALES',
    'SUPPORT',
  ]

  return rolesWithAdminAreaAccess.includes(user.value?.role as string)
})

async function signOut() {
  try {
    await auth.signOut()
  }
  catch {
    //
  }
}

const { $trpc } = useNuxtApp()

// for whatsapp button
const { data: authData } = useAuth()
const { data: whatsappButtonData, refresh: refreshButtonData } = useLazyAsyncData(() => $trpc.user.getMyRepresentativeData.query())

const { data: notificationsData, pending: _loading, refresh } = useLazyAsyncData(() => $trpc.notifications.notifications.query(), {
  server: false,
})

watch(authData, () => {
  refreshButtonData()
})

interface Notification {
  id: string
  label: string
  type: string
  readAt: Date | null
  onClick?: () => Promise<void> | void
}

const notificationDataSchema = z
  .object({
    points: z.number().nullish(),
    postTitle: z.string().nullish(),
  })
  .nullish()

function parseNotificationData(data: unknown) {
  const parseResult = notificationDataSchema.safeParse(data)
  return parseResult.success ? parseResult.data : null
}

const notifications = computed(() => {
  const notifications = notificationsData.value || []

  return notifications.map((notification) => {
    let label = ''

    const points = parseNotificationData(notification.data)?.points

    switch (notification.type) {
      case 'community_post_upvote':
      {
        const postTitle = parseNotificationData(notification.data)?.postTitle || ''

        label = postTitle ? `Il tuo post ${postTitle} ha ricevuto un mi piace!` : `Il tuo post ha ricevuto un mi piace!`
        break
      }
      case 'community_post_comment_upvote':
        label = 'Il tuo commento ha ricevuto un mi piace!'
        break
      case 'purchased_basic_plan':
        label = points ? `Hai ottenuto ${points} punt${points > 1 ? 'i' : 'o'} per l'acquisto del piano Base` : 'Hai ottenuto dei punti per l\'acquisto del piano Base'
        break
      case 'purchased_gold_plan':
        label = points ? `Hai ottenuto ${points} punt${points > 1 ? 'i' : 'o'} per l'acquisto del piano Gold` : 'Hai ottenuto dei punti per l\'acquisto del piano Gold'
        break
      case 'purchased_platinum_plan':
        label = points ? `Hai ottenuto ${points} punt${points > 1 ? 'i' : 'o'} per l'acquisto del piano Platinum` : 'Hai ottenuto dei punti per l\'acquisto del piano Platinum'
        break
      case 'purchased_diamond_plan':
        label = points ? `Hai ottenuto ${points} punt${points > 1 ? 'i' : 'o'} per l'acquisto del piano Diamond` : 'Hai ottenuto dei punti per l\'acquisto del piano Diamond'
        break
      case 'purchased_black_plan':
        label = points ? `Hai ottenuto ${points} punt${points > 1 ? 'i' : 'o'} per l'acquisto del piano Black` : 'Hai ottenuto dei punti per l\'acquisto del piano Black'
        break
      case 'purchased_product':
        label = points ? `Hai ottenuto ${points} punt${points > 1 ? 'i' : 'o'} per l'acquisto di un prodotto` : 'Hai ottenuto dei punti per l\'acquisto di un prodotto'
        break
      case 'hours_watched':
        label = points ? `Hai ottenuto ${points} punt${points > 1 ? 'i' : 'o'} per le tue ore di ascolto` : 'Hai ottenuto dei punti per le tue ore di ascolto'
        break
      case 'course_reminder_notification':
        label = 'Oggi devi riguardare un corso, vai nella sezione dei reminder per vederlo!'
        break
      case 'lesson_reminder_notification':
        label = 'Oggi devi riguardare una lezione, vai nella sezione dei reminder per vederla!'
        break
      case 'tutor_chat_message':
        label = 'Hai ricevuto un messaggio dal tuo tutor'
        break
      default:
        label = 'Hai una notifica'
    }

    return {
      id: notification.id,
      label,
      type: notification.type,
      readAt: notification.readAt,
      createdAt: notification.createdAt,
    }
  })
})

async function performNotificationDefaultAction(notification: Notification) {
  if (notification.type === 'lesson_reminder_notification' || notification.type === 'course_reminder_notification') {
    await navigateTo('/reminders')
  }

  if (notification.type === 'tutor_chat_message') {
    await navigateTo('/tutor')
  }

  await $trpc.notifications.markNotificationAsRead.mutate({
    notificationId: notification.id,
  })

  notification.readAt = new Date()

  await refresh()
}

async function markNotificationAsRead(notification: Notification) {
  await $trpc.notifications.markNotificationAsRead.mutate({
    notificationId: notification.id,
  })

  notification.readAt = new Date()

  await refresh()
}

async function markAllNotificationsAsRead() {
  await $trpc.notifications.markAllNotificationAsRead.mutate()
  await refresh()
}

const focusTargetRef = ref(null)
const popUpStorage = 'oct_22_offer_2024'

const localPopUpStorage = useLocalStorage('popup', '')
const EXPIRED = (new Date() > new Date('2024-10-22T23:59:00+01:00'))
const showCurrentPopup = ref(false)

function closeCurrentPopup() {
  showCurrentPopup.value = false
  localPopUpStorage.value = popUpStorage
}

const isBannerExpired = computed(() => {
  return new Date() > new Date('2024-06-18T23:59')
})

const isPopupClosed2 = ref(false)
const focusTargetRef2 = ref(null)
const popUpStorage2 = 'june_8_offer_2024'

const showCurrentPopup2 = computed(() => {
  return false

  if (import.meta.dev) {
    return false
  }

  if (isPopupClosed2.value) {
    return false
  }

  if (typeof window !== 'undefined') {
    if (import.meta.dev) {
      return true
    }

    if (localStorage.getItem('popup') === popUpStorage2) {
      return false
    }
  }

  return (new Date() >= new Date('2024-06-07T23:59:00+01:00') && new Date() <= new Date('2024-06-18T23:59:00+01:00'))
})

function closeCurrentPopup2() {
  isPopupClosed2.value = true
  if (typeof window !== 'undefined') {
    localStorage.setItem('popup', popUpStorage2)
  }
}

const isNavigationMenuOpen = ref(false)
const isUserMenuOpen = ref(false)
function openNavigationMenu() {
  isNavigationMenuOpen.value = true
  isUserMenuOpen.value = false
}
function openUserMenu() {
  isUserMenuOpen.value = true
  isNavigationMenuOpen.value = false
}
</script>

<template>
  <div class="flex flex-col flex-1 text-black bg-gray-100 dark:bg-neutral-900">
    <Disclosure
      v-slot="{ open }"
      as="nav"
      class="sticky top-0 z-20 w-full bg-white shadow-md dark:shadow-none dark:border-b dark:border-neutral-700/70 dark:bg-neutral-900 dark:text-white"
    >
      <div class="flex flex-col-reverse md:flex-col w-full">
        <!-- Banner Offer -->
        <template v-if="!isBannerExpired">
          <div v-if="!open" class="w-full flex z-20 items-center justify-center flex-1 bg-default-blue p-2 flex-wrap shadow-md text-white">
            <div class="flex justify-around flex-col md:flex-row items-center gap-2 md:gap-5 text-center">
              <div class="p-1 font-bold tracking-tight">
                È uscito ALPHA MINDSET! Corso segreto per forgiare un MINDSET da vero uomo di successo
              </div>
              <div class="bg-green-500 rounded-md p-1 font-bold tracking-tight">
                <a target="_blank" href="https://mindset-alpha.com/?fonte_wbe=popup">
                  Clicca qui e scopri di più
                </a>
              </div>
            </div>
          </div>
        </template>

        <div class="w-full px-4 flex md:mx-auto md:max-w-7xl sm:px-6 lg:px-8">
          <div class="flex flex-grow items-center justify-between lg:gap-2 md:gap-0">
            <!-- Logo -->
            <div class="hidden md:block relative self-start flex-shrink-0 w-[128px]">
              <NuxtLink
                to="/"
                class="flex items-center justify-center py-2 absolute top-0 inset-x-0 bg-default-blue rounded-b-[20%]"
              >
                <svg
                  class="w-[80px]" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 93.917 80.115"
                >
                  <defs>
                    <clipPath id="clip-path">
                      <rect id="Rettangolo_59" data-name="Rettangolo 59" width="93.917" height="80.115" fill="none" />
                    </clipPath>
                    <clipPath id="clip-path-3">
                      <path
                        id="Tracciato_127" data-name="Tracciato 127"
                        d="M100.177,32.153l-.317,2.76a5.794,5.794,0,0,1,4.732-.21l5.94-2.627-2.205-3.09-4.714-1.706Z"
                        transform="translate(-99.86 -27.279)" fill="none"
                      />
                    </clipPath>
                    <clipPath id="clip-path-4">
                      <rect id="Rettangolo_57" data-name="Rettangolo 57" width="6.732" height="7.826" fill="none" />
                    </clipPath>
                    <clipPath id="clip-path-5">
                      <rect id="Rettangolo_58" data-name="Rettangolo 58" width="8.488" height="7.158" fill="none" />
                    </clipPath>
                  </defs>
                  <g id="Raggruppa_107" data-name="Raggruppa 107" clip-path="url(#clip-path)">
                    <g id="Raggruppa_98" data-name="Raggruppa 98">
                      <g id="Raggruppa_97" data-name="Raggruppa 97" clip-path="url(#clip-path)">
                        <path
                          id="Tracciato_71" data-name="Tracciato 71"
                          d="M85.22,123.406A45.177,45.177,0,0,1,70.384,133.85a45.174,45.174,0,0,1-14.835-10.444c-.349.035-.7.068-1.048.106-2.464.263-5.02.611-7.609,1.042a44.53,44.53,0,0,0,9.537,9.714,58.461,58.461,0,0,0,12.648,7.211,3.111,3.111,0,0,0,2.616,0,58.461,58.461,0,0,0,12.648-7.211,44.53,44.53,0,0,0,9.537-9.714c-2.59-.431-5.145-.779-7.609-1.042-.353-.038-.7-.071-1.048-.106"
                          transform="translate(-23.426 -61.651)" fill="#fff"
                        />
                        <path
                          id="Tracciato_72" data-name="Tracciato 72"
                          d="M46.147,34.275V33.246c2.924-2.174,10.543-7.126,19.488-7.349l.147,0c.16,0,.32-.006.48-.006s.32,0,.48.006l.147,0c8.945.222,16.563,5.175,19.488,7.349V48.325c3.2.3,5.659.646,7.506.975V31.432a3.84,3.84,0,0,0-1.277-2.82,47.6,47.6,0,0,0-13.892-7.963,37.181,37.181,0,0,0-10.59-2.25l-.238-.013q-.336-.018-.673-.028c-.142,0-.284-.007-.425-.01-.176,0-.351-.005-.526-.005s-.351,0-.526.005c-.142,0-.284.005-.425.01q-.337.01-.673.028L64.4,18.4a37.177,37.177,0,0,0-10.59,2.25,47.6,47.6,0,0,0-13.892,7.963,3.838,3.838,0,0,0-1.277,2.82V49.3c1.847-.329,4.3-.675,7.506-.975Z"
                          transform="translate(-19.304 -9.164)" fill="#fff"
                        />
                        <path
                          id="Tracciato_73" data-name="Tracciato 73"
                          d="M77.715,70.147c-4.318-.295-9.692-.5-16.337-.509-6.645.012-12.02.214-16.337.509-10.419.712-14.7,1.962-15.875,2.379a.455.455,0,0,0-.294.512l3.888,21.638a.457.457,0,0,0,.544.365c4.153-.869,8.282-1.472,12.191-1.89,2.357-.252,4.632-.436,6.784-.567,3.406-.207,6.5-.284,9.1-.294,2.6.01,5.693.087,9.1.294,2.151.131,4.427.315,6.784.567,3.909.417,8.038,1.021,12.191,1.89A.457.457,0,0,0,90,94.675l3.888-21.638a.455.455,0,0,0-.294-.512c-1.179-.416-5.457-1.667-15.875-2.379"
                          transform="translate(-14.42 -34.79)" fill="#373e41"
                        />
                        <path
                          id="Tracciato_74" data-name="Tracciato 74"
                          d="M15.566,60.566C13.538,60.756,3.81,61.759.09,63.8a.177.177,0,0,0-.03.287c.988.889,5.695,5.182,7.655,8.031a.933.933,0,0,1,.155.639,109.156,109.156,0,0,1-2.7,14.248.177.177,0,0,0,.229.215A39.475,39.475,0,0,1,14.391,85.1a.178.178,0,0,0,.157-.209L11.392,67.92a1.213,1.213,0,0,1,.186-.9l4.153-6.179a.178.178,0,0,0-.165-.277"
                          transform="translate(0 -30.257)" fill="#fff"
                        />
                        <path
                          id="Tracciato_75" data-name="Tracciato 75"
                          d="M171.849,63.8c-3.72-2.045-13.448-3.047-15.476-3.237a.178.178,0,0,0-.165.277l4.153,6.179a1.21,1.21,0,0,1,.186.9l-3.156,16.972a.178.178,0,0,0,.157.209,39.462,39.462,0,0,1,8.994,2.122.177.177,0,0,0,.23-.215,109.1,109.1,0,0,1-2.7-14.248.935.935,0,0,1,.155-.639c1.961-2.848,6.668-7.142,7.655-8.031a.177.177,0,0,0-.03-.287"
                          transform="translate(-78.023 -30.258)" fill="#fff"
                        />
                        <path
                          id="Tracciato_76" data-name="Tracciato 76"
                          d="M78.2,23.941l.494-.535,1.626-1.9-.009-.255.332-.613,2.417.565,3.471.856.464.916.579,1.617,1.3,3.951-.346,1.024,5.2,2v3.256l-.359,3.275-.146.892-4.316.668-3.56,1.382-5.906-.45-2.346-2.5-.355-.843-1.3-3.544-.2-5.405Z"
                          transform="translate(-37.588 -10.308)" fill="#fff"
                        />
                        <path
                          id="Tracciato_77" data-name="Tracciato 77"
                          d="M89.426,34.614a2.057,2.057,0,0,1,.246-1.006c.006-.435.007-.848-.022-1.256a1.294,1.294,0,0,1,.721-1.459.769.769,0,0,0,.4-.4,6.693,6.693,0,0,0,.276-1.818c-.007-.152-.69-.279-1.065-.409q-1.789-.623-3.58-1.24l.082-.223c.664.2,1.337.368,1.989.593a6.84,6.84,0,0,0,6.059-.727,3.133,3.133,0,0,0,.435-5.181c-1.054-.891-2.03-1.87-3-2.845a7.07,7.07,0,0,1-1.947-2.8A2.291,2.291,0,0,0,88.8,14.624c-1.343-.549-2.829-.791-3.981-1.961a4.17,4.17,0,0,0,.013,1.034c.292.942.625,1.874.961,2.8a2.817,2.817,0,0,1,.017,1.828c-.627-1.047-1.176-2.1-1.714-3.15a2.681,2.681,0,0,1-.136-.477c-.07-.243.186-.853.109-.934-.412-.433-.815-.572-.423-1.033.263-.309.448-.677.71-.986a1.016,1.016,0,0,1,.529-.32c1.364-.279,1.365-.271,1.462-1.572.011-.146.043-.291.057-.437a9.5,9.5,0,0,0-.008-3.8,8.2,8.2,0,0,1-.137-2.98,1.445,1.445,0,0,0-.506-1.461A5.929,5.929,0,0,0,83.865.211a7.855,7.855,0,0,0-4.746.367A2.639,2.639,0,0,0,77.773,1.7c-1.11,2.289-1.522,4.6.156,6.871a2.8,2.8,0,0,1,.431,1.345.9.9,0,0,0,.5.8q1.654.932,3.279,1.908c.56.335.594.7.149,1.169a.739.739,0,0,0-.178.254c.827.532,1.241,1.211.638,2.214l-4.612-5.316c-.5.6-.934,1.147-1.41,1.665a.648.648,0,0,0-.058.9c.333.553,1.746,2.288,2.054,2.855a23.763,23.763,0,0,1-2.3-2.44c-.193-.265-.373-.382-.7-.192-1.129.655-2.315,1.234-3.389,1.962-1.246.845-1.94,1.888-1.024,3.488,1.04,1.817,1.7,3.824,2.645,5.7a8.347,8.347,0,0,0,7.338,4.853c.886.059,1.773.095,2.717.144-3.765.752-6.994.082-9.445-2.94A17.762,17.762,0,0,1,75.1,34.2c-.068-.041-.133-.084-.2-.126-.006.628-.029,1.256-.1,1.881a17.577,17.577,0,0,0,9.916,1.82,17.2,17.2,0,0,0,4.945-1.294,10.82,10.82,0,0,1-.241-1.865M88.4,20.971a1.837,1.837,0,0,1,2.249,2.351,8.8,8.8,0,0,0-2.37-.143,3.233,3.233,0,0,0,.122-2.208"
                          transform="translate(-35.415 0)" fill="#373e41"
                        />
                        <path
                          id="Tracciato_78" data-name="Tracciato 78"
                          d="M35.465,83.031c.483-.069.724-.1,1.207-.168l.082.6c.723-.1,1.084-.146,1.807-.238-.121-.955-.182-1.432-.3-2.387-.829.106-1.244.161-2.072.274-.442-.327-.664-.49-1.11-.816l-.406-2.857c.342-.437.515-.656.861-1.092.948-.13,1.422-.193,2.37-.312.447.332.671.5,1.114.832l.1.836c-.495.06-.743.091-1.238.153-.03-.238-.046-.358-.076-.6-.745.095-1.117.144-1.861.245l.324,2.383c.834-.114,1.251-.168,2.085-.273.44.332.66.5,1.1.834.139,1.146.208,1.719.347,2.865-.339.429-.508.644-.843,1.073-.911.115-1.366.175-2.275.3-.435-.328-.654-.492-1.093-.818l-.119-.834"
                          transform="translate(-17.32 -37.984)" fill="#fff"
                        />
                        <path
                          id="Tracciato_79" data-name="Tracciato 79"
                          d="M47.65,83.385c-.426-.34-.64-.509-1.07-.847-.3-2.581-.446-3.871-.743-6.452.354-.427.532-.642.89-1.067.95-.1,1.425-.155,2.375-.248.438.343.657.515,1.091.861l.135,1.437c-.495.046-.742.071-1.237.12-.048-.478-.072-.718-.12-1.2-.747.074-1.119.113-1.865.195.261,2.391.391,3.587.652,5.977.725-.08,1.087-.117,1.812-.189-.048-.479-.072-.719-.12-1.2.486-.049.729-.072,1.216-.118.054.575.081.862.135,1.437-.35.419-.524.63-.871,1.05-.913.09-1.369.138-2.281.238"
                          transform="translate(-22.899 -37.354)" fill="#fff"
                        />
                        <path
                          id="Tracciato_80" data-name="Tracciato 80"
                          d="M56.864,73.931c1-.088,1.5-.129,2.505-.205.037.479.055.719.091,1.2l-.623.048c.19,2.4.284,3.6.474,5.994.726-.058,1.089-.085,1.815-.135-.168-2.4-.252-3.6-.42-6l-.623.045L60,73.679c1-.073,1.5-.107,2.507-.168.03.48.044.72.074,1.2l-.623.039c.16,2.5.24,3.744.4,6.24-.362.409-.542.614-.9,1.024-.915.063-1.372.1-2.286.17-.415-.353-.624-.528-1.043-.879-.212-2.492-.318-3.738-.53-6.231l-.623.054c-.043-.479-.064-.719-.106-1.2"
                          transform="translate(-28.408 -36.725)" fill="#fff"
                        />
                        <path
                          id="Tracciato_81" data-name="Tracciato 81"
                          d="M72.117,81.56c-.4-.365-.608-.546-1.016-.909-.143-2.594-.214-3.89-.357-6.484.379-.406.569-.609.951-1.013.953-.048,1.43-.069,2.384-.106.416.368.624.553,1.036.924.089,2.6.134,3.894.222,6.49-.374.4-.56.6-.931,1-.916.036-1.374.056-2.289.1m.182-1.213c.727-.036,1.09-.052,1.818-.081-.1-2.4-.145-3.6-.241-6.008-.749.03-1.123.047-1.871.084.118,2.4.177,3.6.295,6.005"
                          transform="translate(-35.342 -36.493)" fill="#fff"
                        />
                        <path
                          id="Tracciato_82" data-name="Tracciato 82"
                          d="M82.159,81.253c-.014-.481-.021-.721-.034-1.2l.607-.017c-.061-2.4-.091-3.607-.152-6.011l-.625.018c-.014-.481-.021-.722-.034-1.2,1-.028,1.507-.04,2.511-.056.008.48.012.722.02,1.2L83.827,74c.047,2.4.07,3.607.117,6.011.727-.014,1.091-.019,1.819-.027,0-.24,0-.36-.006-.6.486-.006.73-.007,1.216-.009,0,.721.005,1.082.008,1.8-1.929.009-2.893.025-4.822.079"
                          transform="translate(-40.926 -36.361)" fill="#fff"
                        />
                        <path
                          id="Tracciato_83" data-name="Tracciato 83"
                          d="M94.44,81.159c0-.481,0-.721,0-1.2l.607,0c.619-2.883.939-4.324,1.6-7.2.5.006.754.01,1.256.019.576,2.9.854,4.346,1.387,7.247l.606.016-.034,1.2c-.964-.027-1.446-.038-2.411-.054l.02-1.2.606.011c-.083-.482-.125-.724-.208-1.207-.546-.01-.82-.014-1.366-.021-.1.48-.147.72-.245,1.2l.607.008c-.006.481-.01.721-.016,1.2-.964-.013-1.447-.017-2.411-.018m2.8-5.99c-.2.959-.3,1.439-.5,2.4.368,0,.552.007.92.014-.167-.965-.252-1.447-.424-2.412"
                          transform="translate(-47.18 -36.347)" fill="#fff"
                        />
                        <path
                          id="Tracciato_84" data-name="Tracciato 84"
                          d="M116.112,81.77c.027-.48.04-.721.066-1.2l.606.035c.139-2.4.209-3.6.349-6l-.624-.035c.027-.48.04-.721.066-1.2,1.605.089,2.408.14,4.012.259.372.413.557.621.927,1.035l-.228,2.877c-.424.352-.635.529-1.055.884-.833-.062-1.249-.091-2.082-.144-.061.96-.092,1.44-.154,2.4l.606.04c-.033.479-.049.72-.081,1.2-.963-.065-1.444-.094-2.408-.147m2.113-4.692c.739.048,1.109.073,1.848.127l.175-2.4c-.748-.055-1.121-.08-1.87-.128-.062.96-.092,1.44-.154,2.4"
                          transform="translate(-58.007 -36.652)" fill="#fff"
                        />
                        <path
                          id="Tracciato_85" data-name="Tracciato 85"
                          d="M128.134,82.629c.041-.479.061-.719.1-1.2l.6.053c.211-2.4.316-3.594.527-5.99l-.623-.054c.041-.479.061-.719.1-1.2,2,.17,3.005.267,5.005.485-.078.717-.118,1.076-.2,1.793-.5-.054-.744-.08-1.24-.131.025-.239.037-.359.062-.6-.746-.077-1.119-.114-1.866-.184L130.387,78c.738.07,1.107.106,1.845.182-.05.478-.074.718-.123,1.2-.734-.076-1.1-.112-1.834-.181l-.225,2.4c.725.068,1.088.1,1.813.178.025-.239.037-.359.062-.6.485.05.727.076,1.212.129-.078.717-.117,1.076-.2,1.793-1.92-.209-2.881-.3-4.805-.466"
                          transform="translate(-64.013 -37.09)" fill="#fff"
                        />
                        <path
                          id="Tracciato_86" data-name="Tracciato 86"
                          d="M140.134,83.853c.055-.477.082-.717.137-1.194l.6.07.706-5.971-.622-.072.138-1.195c1.6.184,2.4.284,3.994.5.348.435.521.653.865,1.089l-.4,2.859-.416.306.317.408-.365,2.621.6.085c-.068.476-.1.714-.17,1.19-.957-.136-1.436-.2-2.394-.326l.155-1.193.6.08.318-2.384c-.731-.1-1.1-.144-1.829-.235l-.3,2.387.6.076c-.061.477-.091.716-.152,1.193-.959-.122-1.439-.18-2.4-.291m2.391-4.558c.736.092,1.1.139,1.84.237.127-.953.19-1.43.317-2.384-.744-.1-1.116-.147-1.861-.239l-.3,2.387"
                          transform="translate(-70.008 -37.714)" fill="#fff"
                        />
                        <path
                          id="Tracciato_87" data-name="Tracciato 87"
                          d="M56.348,104.2l-.182-1.439.73-.09c-.349-2.879-.523-4.319-.873-7.2l-.765.095c-.073-.575-.109-.863-.182-1.438,1.979-.25,2.969-.359,4.953-.545.539.416.807.625,1.34,1.045.119,1.387.178,2.08.3,3.467l-.413.472.487.394c.109,1.272.163,1.908.272,3.179l.732-.061c.047.578.07.867.116,1.445-1.159.094-1.739.146-2.9.263-.058-.577-.088-.866-.146-1.443l.732-.072c-.111-1.154-.166-1.732-.277-2.886-.895.086-1.342.132-2.236.231.128,1.153.192,1.729.32,2.882l.731-.079c.061.577.092.865.153,1.442-1.157.123-1.736.19-2.891.336m1.528-6.022c.9-.1,1.354-.147,2.258-.233-.111-1.155-.166-1.732-.276-2.887-.921.088-1.382.136-2.3.238.128,1.153.192,1.729.32,2.882"
                          transform="translate(-27.515 -46.752)" fill="#fff"
                        />
                        <path
                          id="Tracciato_88" data-name="Tracciato 88"
                          d="M72.815,102.937c-.041-.578-.061-.868-.1-1.446.293-.021.439-.031.732-.05l-.473-7.235-.769.052c-.041-.578-.061-.868-.1-1.446,1.241-.088,1.863-.125,3.105-.188l.073,1.448-.769.041.4,7.239.733-.039.073,1.448c-1.161.059-1.741.094-2.9.175"
                          transform="translate(-36.021 -46.273)" fill="#fff"
                        />
                        <path
                          id="Tracciato_89" data-name="Tracciato 89"
                          d="M83.028,102.472c-.479-.449-.721-.673-1.208-1.117-.126-3.129-.189-4.694-.314-7.823.474-.484.714-.725,1.2-1.2,1.181-.038,1.772-.051,2.954-.067.5.457.754.687,1.25,1.149,0,.7.005,1.044.008,1.739-.611,0-.916.006-1.526.016-.008-.58-.013-.87-.022-1.45-.924.014-1.386.024-2.31.052.088,2.9.131,4.348.219,7.247.88-.027,1.32-.037,2.2-.05-.009-.58-.013-.87-.022-1.45.593-.009.889-.012,1.483-.015,0,.7.005,1.044.009,1.74-.466.466-.7.7-1.156,1.17-1.1.015-1.656.027-2.76.063"
                          transform="translate(-40.719 -46.091)" fill="#fff"
                        />
                        <path
                          id="Tracciato_90" data-name="Tracciato 90"
                          d="M96.458,102.41c-.459-.47-.69-.7-1.156-1.17.016-3.132.024-4.7.039-7.83.5-.461.746-.691,1.25-1.148,1.182.015,1.772.029,2.954.067.482.48.722.721,1.2,1.2l-.07,1.738c-.61-.025-.915-.035-1.525-.054.018-.58.027-.87.044-1.449-.923-.028-1.385-.039-2.309-.053-.044,2.9-.066,4.35-.109,7.25.88.014,1.32.023,2.2.05l.044-1.449c.593.018.889.029,1.482.052-.028.7-.042,1.043-.07,1.738-.487.445-.729.669-1.209,1.117-1.1-.036-1.656-.048-2.76-.063"
                          transform="translate(-47.611 -46.092)" fill="#fff"
                        />
                        <path
                          id="Tracciato_91" data-name="Tracciato 91"
                          d="M108.35,102.762l.073-1.448.733.039c.16-2.9.24-4.343.4-7.239l-.769-.041c.029-.579.044-.869.073-1.448,1.243.063,1.864.1,3.105.188-.041.578-.061.868-.1,1.446l-.769-.052c-.076,1.157-.114,1.736-.189,2.893.905.06,1.358.093,2.262.165.093-1.156.14-1.734.233-2.89l-.769-.06c.044-.578.066-.867.109-1.446,1.242.094,1.862.147,3.1.266-.056.577-.083.866-.138,1.443l-.768-.072c-.262,2.888-.393,4.332-.655,7.221l.732.068c-.055.577-.083.866-.138,1.444-1.158-.111-1.738-.161-2.9-.248.044-.578.065-.868.109-1.446.293.023.439.034.732.058l.233-2.891c-.9-.072-1.344-.1-2.24-.163-.076,1.157-.114,1.736-.189,2.893.293.019.439.029.733.05-.041.578-.061.868-.1,1.446-1.16-.082-1.74-.117-2.9-.175"
                          transform="translate(-54.13 -46.273)" fill="#fff"
                        />
                        <path
                          id="Tracciato_92" data-name="Tracciato 92"
                          d="M124.281,104.048c.061-.577.092-.865.152-1.442.293.031.439.047.731.079.32-2.882.48-4.324.8-7.206L125.2,95.4c.061-.577.092-.865.153-1.442,1.239.131,1.858.2,3.094.359-.073.575-.109.863-.182,1.438l-.766-.095c-.349,2.879-.523,4.318-.873,7.2.292.035.438.053.73.09-.073.575-.11.863-.182,1.439-1.155-.146-1.733-.213-2.89-.336"
                          transform="translate(-62.088 -46.938)" fill="#fff"
                        />
                        <path
                          id="Tracciato_93" data-name="Tracciato 93"
                          d="M73,125.259c-.013-.137-.02-.2-.032-.341l.176-.017c-.063-.681-.095-1.022-.158-1.7l-.179.017c-.013-.136-.02-.2-.032-.34.459-.044.689-.064,1.147-.1l.31.249c.027.327.04.491.067.819l-.1.111.115.094c.027.327.04.491.067.819l-.259.3c-.449.038-.674.058-1.123.1m.4-1.412.533-.047-.058-.682-.536.047c.025.272.037.409.061.681m.092,1.022.528-.046c-.023-.273-.035-.409-.058-.682l-.531.046c.025.273.037.409.061.682"
                          transform="translate(-36.359 -61.334)" fill="#373e41"
                        />
                        <path
                          id="Tracciato_94" data-name="Tracciato 94"
                          d="M78.59,122.4c.287-.02.431-.029.719-.047.009.136.013.2.021.341l-.179.011c.044.683.065,1.024.109,1.707l.529-.032-.1-1.707-.179.011c-.009-.137-.013-.2-.021-.341.287-.018.431-.026.719-.041.007.137.011.2.019.341l-.179.01c.039.711.059,1.065.1,1.776l-.266.289c-.267.016-.4.024-.668.041l-.3-.255c-.048-.71-.071-1.065-.119-1.775l-.179.012c-.01-.137-.014-.2-.024-.341"
                          transform="translate(-39.262 -61.102)" fill="#373e41"
                        />
                        <path
                          id="Tracciato_95" data-name="Tracciato 95"
                          d="M84.856,124.011l.353-.014c0,.069,0,.1.006.171l.529-.019c-.009-.274-.013-.41-.022-.684l-.6.022-.3-.262c-.013-.328-.02-.492-.033-.82l.276-.285c.274-.011.41-.016.684-.024l.3.265c0,.1,0,.144.007.239l-.358.011c0-.068,0-.1-.006-.171l-.537.019c.011.274.015.41.026.684l.605-.021.293.265c.009.328.015.492.025.821l-.274.282-.669.023-.293-.262c0-.1-.006-.144-.009-.239"
                          transform="translate(-42.359 -60.997)" fill="#373e41"
                        />
                        <path
                          id="Tracciato_96" data-name="Tracciato 96"
                          d="M90.272,124.4c0-.137,0-.205-.006-.342l.177,0-.025-1.71-.179,0c0-.137,0-.205-.006-.342.288-.005.431-.007.719-.01,0,.137,0,.205,0,.342l-.179,0c.008.684.012,1.026.02,1.71l.176,0c0,.137,0,.205,0,.342-.282,0-.422,0-.7.009"
                          transform="translate(-45.079 -60.945)" fill="#373e41"
                        />
                        <path
                          id="Tracciato_97" data-name="Tracciato 97"
                          d="M94.6,124.378c0-.137,0-.205,0-.342h.177c0-.684.006-1.026.009-1.71h-.18c0-.137,0-.206,0-.342l.54,0c.283.618.422.928.7,1.547l.018-1.2-.18,0c0-.137,0-.205,0-.342.288,0,.432.006.72.012,0,.137,0,.205-.007.342l-.179,0c-.015.821-.023,1.231-.037,2.052l-.352-.006c-.273-.62-.411-.929-.692-1.547l-.01,1.2h.177l0,.342-.7,0"
                          transform="translate(-47.262 -60.94)" fill="#373e41"
                        />
                        <path
                          id="Tracciato_98" data-name="Tracciato 98"
                          d="M101.008,124.495c0-.137.007-.205.011-.342l.177.006c.024-.684.035-1.026.059-1.709l-.179-.006c0-.137.007-.205.011-.342.575.019.863.03,1.438.057-.009.2-.014.307-.023.512l-.358-.016c0-.069,0-.1.007-.171l-.538-.022c-.01.274-.015.41-.026.684l.534.022c-.005.137-.008.205-.014.342l-.532-.021c-.011.273-.016.41-.026.684l.529.021c0-.069,0-.1.007-.171l.353.016c-.009.205-.014.308-.023.513-.563-.026-.845-.037-1.408-.055"
                          transform="translate(-50.462 -60.999)" fill="#373e41"
                        />
                        <path
                          id="Tracciato_99" data-name="Tracciato 99"
                          d="M106.75,124.262l.353.022c0,.068-.007.1-.011.171l.529.034c.018-.273.028-.41.046-.683l-.6-.039-.268-.29c.02-.328.029-.491.048-.819l.3-.256.683.044.268.293c-.007.1-.01.143-.017.239l-.357-.025c0-.068.007-.1.012-.171l-.537-.035c-.017.273-.026.41-.042.683l.605.039.266.293-.058.819-.3.254-.668-.043-.265-.29c.005-.1.008-.144.014-.239"
                          transform="translate(-53.323 -61.149)" fill="#373e41"
                        />
                        <path
                          id="Tracciato_100" data-name="Tracciato 100"
                          d="M112.108,124.653l.353.03c-.006.068-.009.1-.015.17l.528.047c.025-.272.038-.409.062-.681l-.6-.054-.261-.3c.028-.327.041-.491.068-.818l.31-.248c.273.023.409.036.682.061l.261.3-.023.238-.357-.033c.006-.068.009-.1.016-.171l-.536-.048c-.024.273-.036.409-.059.682l.6.054.258.3c-.031.327-.046.49-.077.818l-.307.246-.667-.06-.258-.3.02-.239"
                          transform="translate(-55.997 -61.349)" fill="#373e41"
                        />
                        <path
                          id="Tracciato_101" data-name="Tracciato 101"
                          d="M78.973,130.317l.343-.023c0,.068.007.1.012.171l.514-.033c-.017-.273-.025-.409-.042-.683-.235.014-.352.022-.587.038l-.295-.254c-.023-.328-.034-.492-.057-.819l.261-.292.665-.042.3.257c.006.1.009.144.014.239l-.348.02-.011-.171-.523.033.045.683.588-.037.293.257.048.82-.258.29c-.26.016-.39.024-.65.041l-.292-.255c-.007-.1-.01-.143-.017-.239"
                          transform="translate(-39.398 -64.136)" fill="#373e41"
                        />
                        <path
                          id="Tracciato_102" data-name="Tracciato 102"
                          d="M84.53,130.542l-.286-.262c-.033-.738-.049-1.107-.082-1.845l.268-.286.665-.026.289.264c.006.164.009.246.014.41l-.348.012-.013-.342-.523.02c.029.684.043,1.025.071,1.709l.515-.02-.013-.342.344-.012c.006.164.009.246.014.41l-.265.283-.651.026"
                          transform="translate(-42.046 -64.008)" fill="#373e41"
                        />
                        <path
                          id="Tracciato_103" data-name="Tracciato 103"
                          d="M89.5,130.4c0-.137,0-.205-.007-.342l.172,0c-.013-.684-.019-1.026-.032-1.71l-.175,0c0-.137,0-.205-.007-.342l.7-.012.005.342-.175,0c0,.274.007.41.011.684l.52-.007-.008-.684-.174,0c0-.137,0-.205,0-.342.28,0,.42-.005.7-.007,0,.137,0,.205,0,.342h-.174l.013,1.71h.172c0,.137,0,.205,0,.342l-.685.006c0-.137,0-.205,0-.342l.172,0c0-.274,0-.41-.007-.684l-.518.007c0,.274.006.41.01.684l.172,0c0,.137,0,.205,0,.342-.274,0-.411.006-.685.012"
                          transform="translate(-44.69 -63.939)" fill="#373e41"
                        />
                        <path
                          id="Tracciato_104" data-name="Tracciato 104"
                          d="M95.715,130.387l-.272-.276c.005-.739.008-1.108.014-1.847l.282-.271L96.4,128l.275.278c-.014.739-.021,1.108-.035,1.847l-.279.269-.651-.008m.072-.341.515.007c.01-.684.016-1.026.027-1.71l-.524-.007c-.007.684-.011,1.026-.018,1.71"
                          transform="translate(-47.681 -63.942)" fill="#373e41"
                        />
                        <path
                          id="Tracciato_105" data-name="Tracciato 105"
                          d="M100.923,130.5l-.265-.282c.024-.739.036-1.108.059-1.847l.289-.264.665.025.268.285c-.032.738-.048,1.107-.08,1.846l-.285.262-.651-.025m.081-.339.515.02.068-1.709-.523-.02-.06,1.709"
                          transform="translate(-50.287 -64.002)" fill="#373e41"
                        />
                        <path
                          id="Tracciato_106" data-name="Tracciato 106"
                          d="M105.84,130.717c.008-.137.012-.2.019-.341l.172.009c.04-.683.06-1.024.1-1.707l-.175-.01c.008-.137.012-.205.02-.342l.7.042c-.009.136-.013.2-.022.341l-.175-.011c-.042.683-.063,1.024-.1,1.707l.514.033c0-.068.007-.1.012-.171l.343.023-.036.512c-.547-.038-.82-.055-1.368-.086"
                          transform="translate(-52.876 -64.109)" fill="#373e41"
                        />
                      </g>
                    </g>
                    <g id="Raggruppa_106" data-name="Raggruppa 106" transform="translate(49.972 13.651)">
                      <g id="Raggruppa_105" data-name="Raggruppa 105" clip-path="url(#clip-path-3)">
                        <path
                          id="Tracciato_107" data-name="Tracciato 107"
                          d="M103.621,35.93a6.439,6.439,0,0,1,4.483-6.046,9.594,9.594,0,0,0-3.7-1.464,8.623,8.623,0,0,0-3.455,8.621,5.459,5.459,0,0,1,2.673-1.111"
                          transform="translate(-100.309 -27.85)" fill="#a4bd5d"
                        />
                        <path
                          id="Tracciato_108" data-name="Tracciato 108"
                          d="M103.635,36.009a6.565,6.565,0,0,1,4.259-5.976,9.471,9.471,0,0,0-3.245-1.224,8.48,8.48,0,0,0-3.394,8.14,5.533,5.533,0,0,1,2.381-.94"
                          transform="translate(-100.492 -28.043)" fill="#4e7735"
                        />
                        <path
                          id="Tracciato_109" data-name="Tracciato 109"
                          d="M102.212,35.733a.709.709,0,0,0-.211-.606,8.207,8.207,0,0,1,1.856-4.182,3.468,3.468,0,0,0,1-.62,9.342,9.342,0,0,1,.872-.722,9.315,9.315,0,0,1,1.594.564,8.107,8.107,0,0,0-.893.57.628.628,0,0,0-.321.618,6.857,6.857,0,0,0-2.192,3.418.991.991,0,0,0-.367.618,5.732,5.732,0,0,0-.055.917,5.624,5.624,0,0,0-1.25.452,6.738,6.738,0,0,1-.031-1.029"
                          transform="translate(-100.93 -28.441)" fill="#a4bd5d"
                        />
                        <path
                          id="Tracciato_110" data-name="Tracciato 110"
                          d="M103.494,36.4a2.349,2.349,0,0,0,1.624-.926c.31-.478.128-1-.585-1.079a2,2,0,0,0-1.874,1.1c-.248.638.191.979.834.9"
                          transform="translate(-101.224 -30.828)" fill="#4e7735"
                        />
                        <path
                          id="Tracciato_111" data-name="Tracciato 111"
                          d="M107.448,32.327a1.23,1.23,0,0,0,.9-.289c.235-.207.226-.442-.058-.521a1.1,1.1,0,0,0-.958.3c-.229.241-.162.466.114.511"
                          transform="translate(-103.525 -29.385)" fill="#4e7735"
                        />
                        <path
                          id="Tracciato_112" data-name="Tracciato 112"
                          d="M103.536,40.523a.719.719,0,0,0,.489-.532c.05-.246-.087-.435-.332-.4a.7.7,0,0,0-.529.569c-.036.269.142.42.372.362"
                          transform="translate(-101.508 -33.428)" fill="#4e7735"
                        />
                        <g
                          id="Raggruppa_101" data-name="Raggruppa 101" transform="translate(0.565 1.367)" opacity="0.5"
                          style="mix-blend-mode: multiply;isolation: isolate"
                        >
                          <g id="Raggruppa_100" data-name="Raggruppa 100">
                            <g id="Raggruppa_99" data-name="Raggruppa 99" clip-path="url(#clip-path-4)">
                              <path
                                id="Tracciato_113" data-name="Tracciato 113"
                                d="M100.99,37.494A8.714,8.714,0,0,1,105.9,30.01a9.938,9.938,0,0,1,1.825.876,6.345,6.345,0,0,0-3.986,5.838,5.457,5.457,0,0,0-2.674,1.111q-.041-.169-.073-.342"
                                transform="translate(-100.99 -30.01)" fill="gray"
                              />
                            </g>
                          </g>
                        </g>
                        <path
                          id="Tracciato_114" data-name="Tracciato 114"
                          d="M103.888,37.744a6.439,6.439,0,0,1,5.794-4.8,9.6,9.6,0,0,0-3.245-2.3,8.623,8.623,0,0,0-5.411,7.549,5.46,5.46,0,0,1,2.861-.442"
                          transform="translate(-100.443 -28.957)" fill="#a4bd5d"
                        />
                        <path
                          id="Tracciato_115" data-name="Tracciato 115"
                          d="M103.942,37.8a6.565,6.565,0,0,1,5.56-4.789,9.481,9.481,0,0,0-2.86-1.962,8.482,8.482,0,0,0-5.237,7.1,5.533,5.533,0,0,1,2.537-.345"
                          transform="translate(-100.632 -29.16)" fill="#4e7735"
                        />
                        <path
                          id="Tracciato_116" data-name="Tracciato 116"
                          d="M102.967,37.236a.711.711,0,0,0-.061-.639,8.208,8.208,0,0,1,2.8-3.619,3.487,3.487,0,0,0,1.116-.364,9.277,9.277,0,0,1,1.019-.493,9.336,9.336,0,0,1,1.413.927,8.279,8.279,0,0,0-1,.341c-.309.129-.456.33-.458.523a6.856,6.856,0,0,0-2.943,2.8.993.993,0,0,0-.5.513,5.667,5.667,0,0,0-.271.878,5.619,5.619,0,0,0-1.322.141,6.733,6.733,0,0,1,.215-1.006"
                          transform="translate(-101.305 -29.698)" fill="#a4bd5d"
                        />
                        <path
                          id="Tracciato_117" data-name="Tracciato 117"
                          d="M105.161,37.682a2.346,2.346,0,0,0,1.8-.512c.415-.39.364-.943-.311-1.187a2,2,0,0,0-2.083.627c-.393.561-.048,1,.6,1.073"
                          transform="translate(-102.127 -31.584)" fill="#4e7735"
                        />
                        <path
                          id="Tracciato_118" data-name="Tracciato 118"
                          d="M110.183,34.48a1.232,1.232,0,0,0,.945-.066c.278-.145.325-.375.068-.519a1.1,1.1,0,0,0-1,.062c-.28.179-.269.414-.011.523"
                          transform="translate(-104.919 -30.538)" fill="#4e7735"
                        />
                        <path
                          id="Tracciato_119" data-name="Tracciato 119"
                          d="M104.416,41.446a.717.717,0,0,0,.6-.4c.107-.227.02-.443-.227-.467a.7.7,0,0,0-.65.427.293.293,0,0,0,.275.441"
                          transform="translate(-101.982 -33.922)" fill="#4e7735"
                        />
                        <g
                          id="Raggruppa_104" data-name="Raggruppa 104" transform="translate(0.586 2.071)" opacity="0.5"
                          style="mix-blend-mode: multiply;isolation: isolate"
                        >
                          <g id="Raggruppa_103" data-name="Raggruppa 103">
                            <g id="Raggruppa_102" data-name="Raggruppa 102" clip-path="url(#clip-path-5)">
                              <path
                                id="Tracciato_120" data-name="Tracciato 120"
                                d="M106.947,31.417a9.751,9.751,0,0,1,2.573,1.936,6.41,6.41,0,0,0-5.629,4.781,5.461,5.461,0,0,0-2.859.441,8.623,8.623,0,0,1,5.916-7.158"
                                transform="translate(-101.031 -31.417)" fill="gray"
                              />
                            </g>
                          </g>
                        </g>
                        <path
                          id="Tracciato_121" data-name="Tracciato 121"
                          d="M103.932,38.733a6.437,6.437,0,0,1,6.283-4.142,9.6,9.6,0,0,0-2.975-2.644,8.623,8.623,0,0,0-6.2,6.914,5.461,5.461,0,0,1,2.893-.128"
                          transform="translate(-100.449 -29.611)" fill="#a4bd5d"
                        />
                        <path
                          id="Tracciato_122" data-name="Tracciato 122"
                          d="M104.028,38.77a6.566,6.566,0,0,1,6.05-4.154,9.483,9.483,0,0,0-2.629-2.262,8.482,8.482,0,0,0-5.98,6.483,5.532,5.532,0,0,1,2.559-.067"
                          transform="translate(-100.664 -29.814)" fill="#4e7735"
                        />
                        <path
                          id="Tracciato_123" data-name="Tracciato 123"
                          d="M103.227,38.093a.708.708,0,0,0,.009-.641,8.212,8.212,0,0,1,3.177-3.292,3.485,3.485,0,0,0,1.149-.24,9.215,9.215,0,0,1,1.066-.379,9.326,9.326,0,0,1,1.3,1.075,8.355,8.355,0,0,0-1.034.23c-.321.094-.489.278-.512.47a6.852,6.852,0,0,0-3.231,2.46.99.99,0,0,0-.557.454,5.718,5.718,0,0,0-.366.843,5.642,5.642,0,0,0-1.329,0,6.724,6.724,0,0,1,.324-.977"
                          transform="translate(-101.38 -30.407)" fill="#a4bd5d"
                        />
                        <path
                          id="Tracciato_124" data-name="Tracciato 124"
                          d="M105.9,38.4a2.344,2.344,0,0,0,1.843-.313c.455-.343.465-.9-.179-1.214a2,2,0,0,0-2.139.4c-.451.514-.156.985.475,1.132"
                          transform="translate(-102.529 -31.994)" fill="#4e7735"
                        />
                        <path
                          id="Tracciato_125" data-name="Tracciato 125"
                          d="M111.318,35.665a1.228,1.228,0,0,0,.946.038c.292-.114.364-.337.124-.508a1.1,1.1,0,0,0-1-.049c-.3.148-.312.382-.068.519"
                          transform="translate(-105.499 -31.16)" fill="#4e7735"
                        />
                        <path
                          id="Tracciato_126" data-name="Tracciato 126"
                          d="M104.808,41.948a.717.717,0,0,0,.642-.332c.131-.214.068-.438-.175-.489a.7.7,0,0,0-.693.353.293.293,0,0,0,.226.468"
                          transform="translate(-102.194 -34.192)" fill="#4e7735"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </NuxtLink>
            </div>

            <div class="flex-nowrap hidden md:flex">
              <NuxtLink
                v-for="n in navigationItems" :key="n.name" v-slot="{ href, navigate, isExactActive }" custom
                :href="n.href"
              >
                <a
                  v-bind="$attrs" :href="href" :class="[isExactActive ? 'border-[#0095FF]' : 'border-transparent']"
                  class="transition lg:min-w-[7vw] md:min-w-[4vw] inline-flex items-center justify-center lg:px-4 md:px-2 py-4 lg:text-sm md:text-xs font-medium text-gray-400 border-b-4 hover:border-gray-300 hover:text-gray-700 dark:hover:text-gray-300"
                  @click="navigate"
                >
                  {{ n.name }}
                </a>
              </NuxtLink>
            </div>

            <div class="flex-shrink-0 hidden lg:gap-3 md:gap-1 md:flex md:items-center">
              <!-- TODO: style for this button -->
              <ToggleDarkButton />

              <Menu as="div" class="relative">
                <div class="flex">
                  <MenuButton class="relative flex flex-shrink-0 mx-1 text-sm rounded-full focus:outline-none">
                    <span class="sr-only">View notifications</span>
                    <BellIcon class="w-6 h-6" aria-hidden="true" />
                    <div
                      v-if="notifications.length > 0"
                      class="absolute px-1 text-xs text-white rounded-full -right-1 -bottom-1 bg-default-blue"
                    >
                      {{ notifications.length }}
                    </div>
                  </MenuButton>
                </div>
                <transition
                  enter-active-class="transition duration-200 ease-out"
                  enter-from-class="transform scale-95 opacity-0" enter-to-class="transform scale-100 opacity-100"
                  leave-active-class="transition duration-75 ease-in" leave-from-class="transform scale-100 opacity-100"
                  leave-to-class="transform scale-95 opacity-0"
                >
                  <MenuItems
                    class="absolute max-h-[400px] overflow-y-auto custom-scrollbar right-0 z-10 py-1 mt-2 text-gray-700 origin-top-right bg-white shadow-lg rounded-xl w-96 dark:bg-neutral-800 dark:text-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                  >
                    <template v-if="notifications.length === 0">
                      <MenuItem v-slot="{ active }">
                        <button
                          type="button" class="block w-full px-4 py-2 text-sm"
                          :class="[active ? 'bg-gray-100 dark:bg-neutral-700' : '']"
                        >
                          <div class="flex gap-x-3">
                            <div>
                              <InboxIcon class="w-6 h-6 text-black dark:text-gray-200" />
                            </div>
                            <div>
                              <p class="text-left">
                                Nessuna notifica
                              </p>
                            </div>
                          </div>
                        </button>
                      </MenuItem>
                    </template>

                    <template v-else>
                      <MenuItem v-slot="{ active }">
                        <button
                          type="button" class="block w-full px-4 py-2 text-sm"
                          :class="[active ? 'bg-gray-100 dark:bg-neutral-700' : '']"
                          @click="() => markAllNotificationsAsRead()"
                        >
                          <p class="text-center">
                            Segna tutto come letto
                          </p>
                        </button>
                      </MenuItem>

                      <hr class="dark:border-neutral-500">

                      <MenuItem v-for="notification in notifications" :key="notification.id">
                        <button
                          type="button" class="group block w-full px-4 py-2 text-sm"
                          @click="() => performNotificationDefaultAction(notification)"
                        >
                          <div class="flex items-center justify-between">
                            <p class="text-left">
                              {{ notification.label }}
                              <span class="text-xs text-neutral-500">
                                <ClientOnly>
                                  {{ format(notification.createdAt, 'HH:mm - dd/MM/yy') }}
                                </ClientOnly>
                              </span>
                            </p>

                            <button
                              v-if="!notification.readAt" type="button"
                              class="p-2 transition rounded-full dark:group-hover:bg-neutral-700 group-hover:bg-gray-100"
                              @click="() => markNotificationAsRead(notification)"
                            >
                              <XMarkIcon class="w-4 h-4" />
                            </button>
                          </div>
                        </button>
                      </MenuItem>
                    </template>
                  </MenuItems>
                </transition>
              </Menu>

              <!-- Profile dropdown -->
              <Menu as="div" class="relative">
                <div class="flex">
                  <MenuButton
                    class="flex flex-shrink-0 rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-[#0095FF] focus:ring-offset-2"
                  >
                    <span class="sr-only">Open user menu</span>
                    <img class="flex-shrink-0 w-8 h-8 border rounded-full border-opacity-70 object-cover" :src="user.image">
                  </MenuButton>
                </div>
                <transition
                  enter-active-class="transition duration-200 ease-out"
                  enter-from-class="transform scale-95 opacity-0" enter-to-class="transform scale-100 opacity-100"
                  leave-active-class="transition duration-75 ease-in" leave-from-class="transform scale-100 opacity-100"
                  leave-to-class="transform scale-95 opacity-0"
                >
                  <MenuItems
                    class="absolute right-0 z-10 w-48 py-1 mt-2 text-gray-700 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg dark:divide-gray-700 dark:bg-neutral-800 dark:text-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                  >
                    <div class="py-1">
                      <MenuItem v-slot="{ close }">
                        <NuxtLink v-slot="{ href, navigate }" custom to="/lessons">
                          <a
                            class="flex items-center px-4 py-2 text-sm cursor-pointer group hover:bg-gray-100 hover:dark:bg-neutral-700"
                            v-bind="$attrs" :href="href" @click="(e) => { navigate(e), close() }"
                          >
                            <VideoCameraIcon class="w-5 h-5 mr-2" aria-hidden="true" />
                            <p class="transition group-hover:translate-x-1">
                              Videocorsi
                            </p>
                          </a>
                        </NuxtLink>
                      </MenuItem>

                      <MenuItem v-slot="{ close }">
                        <NuxtLink v-slot="{ href, navigate }" custom to="/audiobooks">
                          <a
                            class="flex items-center px-4 py-2 text-sm cursor-pointer group hover:bg-gray-100 hover:dark:bg-neutral-700"
                            v-bind="$attrs" :href="href" @click="(e) => { navigate(e), close() }"
                          >
                            <MicrophoneIcon class="w-5 h-5 mr-2" aria-hidden="true" />
                            <p class="transition group-hover:translate-x-1">
                              Audiolibri
                            </p>
                          </a>
                        </NuxtLink>
                      </MenuItem>

                      <MenuItem v-slot="{ close }">
                        <NuxtLink v-slot="{ href, navigate }" custom to="/watchlist">
                          <a
                            class="flex items-center px-4 py-2 text-sm cursor-pointer group hover:bg-gray-100 hover:dark:bg-neutral-700"
                            v-bind="$attrs" :href="href" @click="(e) => { navigate(e), close() }"
                          >
                            <BookmarkIcon class="w-5 h-5 mr-2" aria-hidden="true" />
                            <p class="transition group-hover:translate-x-1">
                              Preferiti
                            </p>
                          </a>
                        </NuxtLink>
                      </MenuItem>

                      <MenuItem v-slot="{ close }">
                        <NuxtLink v-slot="{ href, navigate }" custom to="/bookmarks">
                          <a
                            class="flex items-center px-4 py-2 text-sm cursor-pointer group hover:bg-gray-100 hover:dark:bg-neutral-700"
                            v-bind="$attrs" :href="href" @click="(e) => { navigate(e), close() }"
                          >
                            <BookmarkSquareIcon class="w-5 h-5 mr-2" aria-hidden="true" />
                            <p class="transition group-hover:translate-x-1">
                              Segnalibri
                            </p>
                          </a>
                        </NuxtLink>
                      </MenuItem>

                      <MenuItem v-slot="{ close }">
                        <NuxtLink v-slot="{ href, navigate }" custom to="/my-posts">
                          <a
                            class="flex items-center px-4 py-2 text-sm cursor-pointer group hover:bg-gray-100 hover:dark:bg-neutral-700"
                            v-bind="$attrs" :href="href" @click="(e) => { navigate(e), close() }"
                          >
                            <NewspaperIcon class="w-5 h-5 mr-2" aria-hidden="true" />
                            <p class="transition group-hover:translate-x-1">
                              I miei post
                            </p>
                          </a>
                        </NuxtLink>
                      </MenuItem>

                      <MenuItem v-slot="{ close }">
                        <NuxtLink v-slot="{ href, navigate }" custom to="/reminders">
                          <a
                            class="flex items-center px-4 py-2 text-sm cursor-pointer group hover:bg-gray-100 hover:dark:bg-neutral-700"
                            v-bind="$attrs" :href="href" @click="(e) => { navigate(e), close() }"
                          >
                            <BellAlertIcon class="w-5 h-5 mr-2" />
                            <p class="transition group-hover:translate-x-1">
                              SRS
                            </p>
                          </a>
                        </NuxtLink>
                      </MenuItem>

                      <MenuItem v-slot="{ close }">
                        <NuxtLink v-slot="{ href, navigate }" custom to="/redeem-gift">
                          <a
                            class="flex items-center px-4 py-2 text-sm cursor-pointer group text-default-blue hover:bg-gray-100 hover:dark:bg-neutral-700"
                            v-bind="$attrs" :href="href" @click="(e) => { navigate(e), close() }"
                          >
                            <GiftIcon class="w-5 h-5 mr-2" aria-hidden="true" />
                            <p class="transition group-hover:translate-x-1">
                              Riscatta un regalo
                            </p>
                          </a>
                        </NuxtLink>
                      </MenuItem>

                      <MenuItem v-slot="{ close }">
                        <NuxtLink v-slot="{ href, navigate }" custom to="/settings">
                          <a
                            class="flex items-center px-4 py-2 text-sm cursor-pointer group text-default-blue hover:bg-gray-100 hover:dark:bg-neutral-700"
                            v-bind="$attrs" :href="href" @click="(e) => { navigate(e), close() }"
                          >
                            <CogIcon class="w-5 h-5 mr-2" aria-hidden="true" />
                            <p class="transition group-hover:translate-x-1">
                              Impostazioni
                            </p>
                          </a>
                        </NuxtLink>
                      </MenuItem>

                      <MenuItem v-slot="{ active }">
                        <button
                          type="button" class="flex items-center w-full px-4 py-2 text-sm text-left text-red-400 group"
                          :class="[active ? 'bg-gray-100 dark:bg-neutral-700' : '']" @click="signOut"
                        >
                          <ArrowUturnLeftIcon class="w-5 h-5 mr-2 rotate-180" aria-hidden="true" />
                          <p class="transition group-hover:translate-x-1">
                            Esci
                          </p>
                        </button>
                      </MenuItem>
                    </div>

                    <div v-if="canAccessAdminArea" class="py-1">
                      <MenuItem v-slot="{ active }">
                        <a
                          href="https://admin.scuolaperricchi.com/"
                          class="block px-4 py-2 text-sm font-bold text-center text-default-blue"
                          :class="[active ? 'bg-gray-100 dark:bg-neutral-700' : '']"
                        >
                          Area Admin
                        </a>
                      </MenuItem>
                    </div>
                  </MenuItems>
                </transition>
              </Menu>
            </div>

            <!-- Open mobile menu button -->
            <div class="flex items-center -mr-2 md:hidden w-full justify-end">
              <!-- <Menu v-if="!open" as="div" class="ml-3">
              <div class="flex">
                <MenuButton class="inline-flex items-center justify-center p-2 rounded-md hover:bg-gray-100 dark:hover:bg-neutral-800">
                  <span class="sr-only">View notifications</span>
                  <BellIcon class="block w-6 h-6 m-2" aria-hidden="true" />
                </MenuButton>
              </div>
              <transition enter-active-class="transition duration-200 ease-out" enter-from-class="transform scale-95 opacity-0" enter-to-class="transform scale-100 opacity-100" leave-active-class="transition duration-75 ease-in" leave-from-class="transform scale-100 opacity-100" leave-to-class="transform scale-95 opacity-0">
                <MenuItems class="absolute right-0 z-10 w-full py-1 mt-2 text-gray-700 origin-top bg-white divide-y rounded-md shadow-lg divide-neutral-700 top-20 dark:bg-neutral-800 dark:text-white">
                  <div class="absolute w-full h-screen bg-opacity-75 backdrop-blur-sm bg-neutral-900">
                    <template v-if="notifications.length === 0">
                      <MenuItem v-slot="{ active }">
                        <button type="button" class="block w-full px-4 py-2 text-sm" :class="[active ? 'bg-gray-100 dark:bg-neutral-700' : '']">
                          <div class="flex gap-x-3">
                            <div>
                              <InboxIcon class="w-6 h-6 text-black dark:text-gray-200" />
                            </div>
                            <div>
                              <p class="text-left">
                                Nessuna notifica
                              </p>
                            </div>
                          </div>
                        </button>
                      </MenuItem>
                    </template>

                    <template v-else>
                      <MenuItem
                        v-for="notification in notifications"
                        :key="notification.id"
                      >
                        <div class="block px-4 py-2 text-sm">
                          <div class="flex items-center gap-x-3">
                            <div v-if="!notification.readAt">
                              <div class="flex-shrink-0 w-2 h-2 rounded-full bg-default-blue" />
                            </div>
                            <div>
                              <p class="text-left">
                                {{ notification.label }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </MenuItem>
                    </template>
                  </div>
                </MenuItems>
              </transition>
            </Menu> -->

              <button v-if="open" type="button" class="inline-flex items-center justify-center p-2 rounded-md">
                <span class="sr-only">Dark or light mode</span>

                <ToggleDarkButton />
              </button>

              <DisclosureButton
                class="inline-flex items-center justify-center p-2 rounded-md hover:bg-gray-100 dark:hover:bg-neutral-800"
              >
                <span class="sr-only">Open main menu</span>

                <div v-if="!open" class="flex">
                  <img class="flex-shrink-0 w-6 h-6 border rounded-full border-opacity-70 object-cover mr-2" :src="user.image" @click="openUserMenu">
                  <Bars3Icon class="block w-6 h-6" aria-hidden="true" @click="openNavigationMenu" />
                </div>
                <XMarkIcon v-else class="block w-6 h-6" aria-hidden="true" />
              </DisclosureButton>
            </div>
          </div>
        </div>
      </div>

      <DisclosurePanel class="md:hidden max-h-screen overflow-auto">
        <div v-if="isNavigationMenuOpen" class="pb-3 sm:space-y-1 pt-10">
          <NuxtLink
            v-for="item in navigationItems" :key="item.name" v-slot="{ href, navigate, isExactActive }"
            :to="item.href" custom
          >
            <DisclosureButton
              as="a" v-bind="$attrs" :href="href"
              class="flex p-1.5 text-sm justify-center w-full font-semibold leading-6 gap-x-3 hover:bg-gray-200 dark:hover:bg-neutral-800"
              @click="navigate"
            >
              <div :class="[isExactActive ? 'border-b-2 border-default-blue' : '']">
                {{ item.name }}
              </div>
            </DisclosureButton>
          </NuxtLink>
        </div>
        <div v-if="isUserMenuOpen" class="pt-4 pb-3 border-t border-gray-200 dark:border-neutral-700">
          <div class="flex justify-between mt-7">
            <div class="flex items-center px-4">
              <div class="flex-shrink-0">
                <img class="w-10 h-10 rounded-full object-cover" :src="user.image" alt="">
              </div>
              <div class="ml-3">
                <div class="text-base font-medium text-gray-800 dark:text-gray-200">
                  {{ user.name }}
                </div>
                <div class="text-sm font-medium text-gray-500">
                  {{ user.email }}
                </div>
              </div>
            </div>
            <div>
              <button
                type="button"
                class="w-full flex px-2 py-1.5 text-red-400 text-sm font-semibold leading-6 hover:bg-gray-200 dark:hover:bg-neutral-800"
                @click="signOut"
              >
                <ArrowUturnLeftIcon class="w-5 h-5 mr-2 rotate-180" aria-hidden="true" />
                Esci
              </button>
            </div>
          </div>

          <div class="mt-3 sm:space-y-1">
            <NuxtLink v-slot="{ href, navigate, isExactActive }" to="/lessons" custom>
              <DisclosureButton
                as="a" v-bind="$attrs" :href="href"
                class="flex px-2 py-1.5 text-sm font-semibold leading-6 hover:bg-gray-200 dark:hover:bg-neutral-800"
                :class="[isExactActive ? 'bg-gray-200 dark:bg-gray-800 border-l-4 border-default-blue' : '']"
                @click="navigate"
              >
                <VideoCameraIcon class="w-5 h-5 mr-2" aria-hidden="true" />
                Videocorsi
              </DisclosureButton>
            </NuxtLink>

            <NuxtLink v-slot="{ href, navigate, isExactActive }" to="/audiobooks" custom>
              <DisclosureButton
                as="a" v-bind="$attrs" :href="href"
                class="flex px-2 py-1.5 text-sm font-semibold leading-6 hover:bg-gray-200 dark:hover:bg-neutral-800"
                :class="[isExactActive ? 'bg-gray-200 dark:bg-gray-800 border-l-4 border-default-blue' : '']"
                @click="navigate"
              >
                <MicrophoneIcon class="w-5 h-5 mr-2" aria-hidden="true" />
                Audiolibri
              </DisclosureButton>
            </NuxtLink>

            <NuxtLink v-slot="{ href, navigate, isExactActive }" to="/watchlist" custom>
              <DisclosureButton
                as="a" v-bind="$attrs" :href="href"
                class="flex px-2 py-1.5 text-sm font-semibold leading-6 hover:bg-gray-200 dark:hover:bg-neutral-800"
                :class="[isExactActive ? 'bg-gray-200 dark:bg-gray-800 border-l-4 border-default-blue' : '']"
                @click="navigate"
              >
                <BookmarkIcon class="w-5 h-5 mr-2" aria-hidden="true" />
                Preferiti
              </DisclosureButton>
            </NuxtLink>

            <NuxtLink v-slot="{ href, navigate, isExactActive }" to="/bookmarks" custom>
              <DisclosureButton
                as="a" v-bind="$attrs" :href="href"
                class="flex px-2 py-1.5 text-sm font-semibold leading-6 hover:bg-gray-200 dark:hover:bg-neutral-800"
                :class="[isExactActive ? 'bg-gray-200 dark:bg-gray-800 border-l-4 border-default-blue' : '']"
                @click="navigate"
              >
                <BookmarkSquareIcon class="w-5 h-5 mr-2" aria-hidden="true" />
                Segnalibri
              </DisclosureButton>
            </NuxtLink>

            <NuxtLink v-slot="{ href, navigate, isExactActive }" to="/my-posts" custom>
              <DisclosureButton
                as="a" v-bind="$attrs" :href="href"
                class="flex px-2 py-1.5 text-sm font-semibold leading-6 hover:bg-gray-200 dark:hover:bg-neutral-800"
                :class="[isExactActive ? 'bg-gray-200 dark:bg-gray-800 border-l-4 border-default-blue' : '']"
                @click="navigate"
              >
                <NewspaperIcon class="w-5 h-5 mr-2" aria-hidden="true" />
                I miei post
              </DisclosureButton>
            </NuxtLink>

            <NuxtLink v-slot="{ href, navigate, isExactActive }" to="/reminders" custom>
              <DisclosureButton
                as="a" v-bind="$attrs" :href="href"
                class="flex px-2 py-1.5 text-sm font-semibold leading-6 hover:bg-gray-200 dark:hover:bg-neutral-800"
                :class="[isExactActive ? 'bg-gray-200 dark:bg-gray-800 border-l-4 border-default-blue' : '']"
                @click="navigate"
              >
                <BellAlertIcon class="w-5 h-5 mr-2" />

                SRS
              </DisclosureButton>
            </NuxtLink>

            <NuxtLink v-slot="{ href, navigate, isExactActive }" to="/redeem-gift">
              <DisclosureButton
                as="a" v-bind="$attrs" :href="href"
                class="flex px-2 py-1.5 text-default-blue text-sm font-semibold leading-6 hover:bg-gray-200 dark:hover:bg-neutral-800"
                :class="[isExactActive ? 'bg-gray-200 dark:bg-gray-800 border-l-4 border-default-blue' : '']"
                @click="navigate"
              >
                <GiftIcon class="w-5 h-5 mr-2" aria-hidden="true" />
                Riscatta un regalo
              </DisclosureButton>
            </NuxtLink>

            <NuxtLink v-slot="{ href, navigate, isExactActive }" to="/settings">
              <DisclosureButton
                as="a" v-bind="$attrs" :href="href"
                class="flex px-2 py-1.5 text-default-blue text-sm font-semibold leading-6 hover:bg-gray-200 dark:hover:bg-neutral-800"
                :class="[isExactActive ? 'bg-gray-200 dark:bg-gray-800 border-l-4 border-default-blue' : '']"
                @click="navigate"
              >
                <CogIcon class="w-5 h-5 mr-2" aria-hidden="true" />
                Impostazioni
              </DisclosureButton>
            </NuxtLink>
          </div>
        </div>
      </DisclosurePanel>
    </Disclosure>

    <!-- Main -->
    <div class="flex flex-col flex-1">
      <slot />
    </div>

    <WhatsappFloatingButton
      ref="whatsappFloatingButton"
      :data="whatsappButtonData"
    />

    <!-- Offer Dialog -->
    <ClientOnly>
      <TransitionRoot as="template" :show="showCurrentPopup">
        <Dialog as="div" class="relative z-50" @close="closeCurrentPopup">
          <TransitionChild
            as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
            leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0"
          >
            <div class="fixed inset-0 bg-neutral-900 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <TransitionChild
                as="template" enter="ease-out duration-300"
                enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                leave-from="opacity-100 translate-y-0 sm:scale-100"
                leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <DialogPanel
                  class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6"
                >
                  <div class="absolute right-0 top-0 block pr-4 pt-4">
                    <button
                      type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                      @click="() => closeCurrentPopup()"
                    >
                      <span class="sr-only">Close</span>
                      <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>

                  <div class="sm:flex sm:items-start">
                    <div class="px-2 md:px-8 py-8 text-black">
                      <DialogTitle as="h3" class="text-center text-2xl pb-2 font-bold underline">
                        Solo fino al 22 ottobre!
                      </DialogTitle>

                      <h2 class="pb-4 uppercase text-4xl font-bold text-center text-black tracking-wide">
                        Ultima Occasione:
                      </h2>

                      <img src="~/assets/img/popup/image1.png" class="max-w-[400px] mb-5 rounded-md mx-auto">

                      <p class="font-bold text-xl text-center text-black max-w-[400px] mx-auto">
                        Inoltre signori, è uscito il mio <span class="text-red-500">nuovo e ultimo corso</span> “Eredità Bigluchiana”
                      </p>

                      <div class="mt-6 text-black">
                        <div class="mt-6 space-y-4">
                          <p class="text-base">
                            Al suo interno è contenuto <b>TUTTO ciò che devi sapere per monetizzare al meglio nel DIFFICILE mercato odierno</b> (comprende anche <b>il nuovissimo VTA 3.0</b>, con il ritorno dei <em>Cicchinelli brothers</em>, e il <b>Meeting della Vergogna</b>, un meeting <em>top secret interno</em> dove “frusto” il team e i dirigenti per rimetterli in carreggiata - pura formazione bigluchiana).
                          </p>

                          <p class="text-base">
                            Oltre <b>20 ore</b> di nuova formazione <b>per lucrare come il Papi comanda</b>.
                          </p>
                        </div>

                        <div class="mt-7 flex flex-col justify-center gap-4">
                          <a
                            href="https://ereditabigluchiana.com/?fonte_wbe=popup"
                            class="rounded-md text-center w-fit mx-auto py-4 px-6 bg-default-blue font-medium text-white hover:text-white/90 focus:outline-none"
                            target="_blank"
                          >
                            APPROFITTA ORA DELL’OFFERTA!
                          </a>
                        </div>

                        <div class="mt-6 space-y-4">
                          <p class="text-base">
                            Inoltre visita anche il nostro <b>nuovo SHOP ONLINE</b> e approfitta dell’offerta in corso, valida <u>solo fino al 22 ottobre</u>.
                          </p>

                          <ul class="list-disc text-black font-bold ml-10">
                            <li class="text-base text-black">
                              1 corso a scelta → 297€
                            </li>
                            <li class="text-base text-black">
                              5 corsi a scelta → 497€ (!!!)
                            </li>
                          </ul>
                        </div>

                        <div class="mt-7 flex flex-col justify-center gap-4">
                          <a
                            ref="focusTargetRef"
                            href="https://fuoritutto.biglucainternational.com/?fonte_wbe=popup"
                            class="rounded-md text-center w-fit mx-auto py-4 px-6 bg-default-blue font-medium text-white hover:text-white/90 focus:outline-none"
                            target="_blank"
                          >
                            VISITA LO SHOP ONLINE
                          </a>
                        </div>

                        <div class="mt-12 py-2">
                          <img class="h-[55px]" src="~/assets/img/big-luca-signature.png">
                        </div>
                      </div>
                    </div>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </TransitionRoot>
    </ClientOnly>

    <ClientOnly v-if="showCurrentPopup2">
      <TransitionRoot as="template" :show="showCurrentPopup2">
        <Dialog :focus="focusTargetRef2" as="div" class="relative z-50">
          <TransitionChild
            as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
            leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0"
          >
            <div class="fixed inset-0 bg-neutral-900 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <TransitionChild
                as="template" enter="ease-out duration-300"
                enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                leave-from="opacity-100 translate-y-0 sm:scale-100"
                leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <DialogPanel
                  class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6"
                >
                  <div class="absolute right-0 top-0 block pr-4 pt-4">
                    <button
                      type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                      @click="closeCurrentPopup2"
                    >
                      <span class="sr-only">Close</span>
                      <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>

                  <div class="sm:flex sm:items-start">
                    <div class="px-2 md:px-8 py-8 text-black">
                      <DialogTitle as="h3" class="text-center text-2xl pb-4 font-bold">
                        Signori, vi presento il <span class="text-[#BF9000]">NUOVO corso segreto <br>del papi</span>
                      </DialogTitle>

                      <img src="~/assets/img/alpha-mindset.png" class="max-w-[400px] mb-5 rounded-md mx-auto">

                      <p class="font-bold text-lg">
                        Ecco tutto ciò che devi sapere su soldi, sesso, famiglia, relazioni e amicizia per <span class="text-[#BF9000]">forgiare un MINDSET da vero uomo di successo</span>
                      </p>

                      <div class="mt-6 text-black">
                        <div class="mt-6 space-y-4">
                          <p class="text-sm">
                            Vorresti <b>iniettare in te stesso una potente dose</b> di autostima, grinta e sicurezza e sviluppare un MINDSET alpha da vero uomo di successo?
                          </p>

                          <p class="text-sm">
                            Vorresti sapere come gestire le relazioni personali e nel business <b>per essere sempre colui che domina e viene rispettato?
                            </b>
                          </p>

                          <p class="text-sm">
                            Ti piacerebbe sapere <b>come individuare i pochi e veri amici che forse hai</b>, smascherare chi trama alle tue spalle e tagliare le amicizie tossiche per il tuo successo?
                          </p>

                          <p class="text-sm">
                            Allora clicca qui, leggi tutti i dettagli e corri a strisciare:
                          </p>
                        </div>

                        <div class="mt-7 flex flex-col justify-center gap-4">
                          <a
                            ref="focusTargetRef"
                            href="https://mindset-alpha.com/?fonte_wbe=popup"
                            class="rounded-md text-center w-fit mx-auto py-4 px-6 bg-default-blue font-medium text-white hover:text-white/90 focus:outline-none"
                            target="_blank"
                          >
                            “Sì, voglio un mindset Alpha!”
                          </a>

                          <p class="text-sm mt-5">
                            <b>P.S.</b>
                            L’offerta è <u><b>valida solo fino al 18 giugno.</b></u>
                          </p>
                        </div>

                        <div class="mt-12 py-2">
                          <img class="h-[55px]" src="~/assets/img/big-luca-signature.png">
                        </div>
                      </div>
                    </div>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </TransitionRoot>
    </ClientOnly>
  </div>
</template>
